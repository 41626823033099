var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.createSpaceAndApp.operationSelected
    ? _c(
        "v-container",
        { staticClass: "pb-0", staticStyle: { "max-width": "700px" } },
        [
          _c(
            "v-stepper",
            { attrs: { value: _vm.createSpaceAndApp.step } },
            [
              _c(
                "v-stepper-header",
                [
                  _c(
                    "v-stepper-step",
                    {
                      attrs: {
                        color: "secondary",
                        step: "1",
                        complete: _vm.createSpaceAndApp.step > 1
                      }
                    },
                    [_vm._v("Create Space")]
                  ),
                  _c("v-divider"),
                  _c(
                    "v-stepper-step",
                    {
                      attrs: {
                        color: "secondary",
                        step: "2",
                        complete: _vm.createSpaceAndApp.step > 2
                      }
                    },
                    [
                      _vm._v("Add Application"),
                      _vm.createSpaceAndApp.skipAppCreation === true
                        ? _c("small", [_vm._v("skipped")])
                        : _vm._e()
                    ]
                  ),
                  _c("v-divider"),
                  _c(
                    "v-stepper-step",
                    {
                      attrs: {
                        color: "secondary",
                        step: "3",
                        complete: _vm.createSpaceAndApp.lastStepCompleted
                      }
                    },
                    [_vm._v("Get Started")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }