<template>
    <v-container v-if="createSpaceAndApp.operationSelected" style="max-width:700px;" class="pb-0">
        <v-stepper :value="createSpaceAndApp.step">
            <v-stepper-header>
                <v-stepper-step color="secondary" step="1" :complete="createSpaceAndApp.step > 1">Create Space</v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step color="secondary" step="2" :complete="createSpaceAndApp.step > 2"
                    >Add Application<small v-if="createSpaceAndApp.skipAppCreation === true">skipped</small></v-stepper-step
                >
                <v-divider></v-divider>
                <v-stepper-step color="secondary" step="3" :complete="createSpaceAndApp.lastStepCompleted">Get Started</v-stepper-step>
            </v-stepper-header>
        </v-stepper>
    </v-container>
</template>
<script>
import { mapState } from 'vuex'
export default {
    computed: {
        ...mapState('homeStore', ['createSpaceAndApp'])
    }
}
</script>
